import 'bootstrap';
import './bootstrap-form-validation';

window.$ = require('jquery');
import './submit-form';

function scrollTo(hash) {
    const targetHeight = $("section" + hash)[0].offsetTop - $(".sticky-top").height();

    $([document.documentElement, document.body]).animate({
        scrollTop: targetHeight
    }, 100);
}

$(window).on('hashchange', function (e) {
    e.preventDefault();
    scrollTo(window.location.hash);
});

if (window.location.hash) {
    scrollTo(window.location.hash);
}