(function () {
    'use strict'

    const form = document.querySelector("form#contactForm");

    if (!form) {
        return;
    }

    console.log("form", form);

    form.addEventListener('submit', function (event) {
        if (!form.checkValidity()) {
            return;
        }

        event.preventDefault();
        event.stopPropagation();

        const acceptTOS = form.querySelector("#acceptTOS").checked;

        // make sure a client accepts our terms of services!
        if (!acceptTOS) {
            return;
        }

        $(event.target).find("button:submit").prop("disabled", true);

        const companyName = form.querySelector("#companyName").value;
        const contactPerson = form.querySelector("#contactPerson").value;
        const field = form.querySelector("#field").value;
        const amountMembers = form.querySelector("#amountMembers").value;
        const requestedService = form.querySelector("#requestedService").value;
        const moreDetails = form.querySelector("#moreDetails").value;

        $.ajax('/api/submitForm.php', {
            type: "POST",
            data: {
                companyName,
                contactPerson,
                field,
                amountMembers,
                requestedService,
                moreDetails
            },
            dataType: "json",
            error: function (data) {
                console.log("received data", data, data.status);
                if (data && data.status && data.status === 200) {
                    console.log("form send!", data);
                    $(event.target).addClass("d-none");
                    $(event.target).parent("div").find(".form-submitted").removeClass("d-none");
                    return;
                }

                console.error("error", data);
                $(event.target).find("button:submit").prop("disabled", false);
            }
        })
    }, false)

})()
